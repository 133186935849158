<template>
	<div class="app-container">
		<customNav :valuechange='valuechange'></customNav>
		<div class="swiper-focus">
			<div class="swiper-img">
				<el-image :src="swiperlist[currentIndicatorIndex][num]" class="item-img" fit="cover" />
				<template v-if="currentIndicatorIndex == 0">
					<template v-if="num == 0">
						<div class="order-btn1-1" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 1">
						<div class="order-btn1-2" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 2">
						<div class="order-btn1-3" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
				</template>

				<template v-if="currentIndicatorIndex == 1">
					<template v-if="num == 0">
						<div class="order-btn2-1" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 1">
						<div class="order-btn2-2" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 2">
						<div class="order-btn2-3" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
				</template>

				<template v-if="currentIndicatorIndex == 2">
					<template v-if="num == 0">
						<div class="order-btn3-1" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 1">
						<div class="order-btn3-2" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 2">
						<div class="order-btn3-3" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
				</template>

				<template v-if="currentIndicatorIndex == 3">
					<template v-if="num == 0">
						<div class="order-btn4-1" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 1">
						<div class="order-btn4-2" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="num == 2">
						<div class="order-btn4-3" @click="handleOrderClick">
							<span>立刻订购</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
				</template>



			</div>
			<div class="swiper-indicator">
				<div v-for="(item,index) in indicatorlist" :key='item.value' class='img'
					@click="handleIndicatorClick(index)" :class="currentIndicatorIndex == index ? 'img' : 'currentimg'">
					<img :src="item.src" />
				</div>
			</div>
			<div class="indicator-arrow">
				<img src="@/assets/image/arrow-down-active.png" @click="handlePrev">
				<img src="@/assets/image/arrow-down-active.png" @click="handleNext">
			</div>
		</div>

		<el-row :gutter="24" style="margin: 0;">
			<div class="introduce box-content" style="padding-top: 100px;">
				<div class="sub-title">产品介绍</div>
				<div class="content">
					<div class="item" v-for="(item,index) in introducelist" :key="index">
						<img :src="item.src" />
						<div class="label">{{item.label}}
						</div>
					</div>
				</div>
			</div>
		</el-row>

		<el-row :gutter="24" style="margin: 0;">
			<div class="scene box-content">
				<div class="sub-title">适用场景</div>
				<div class="img-box" @mouseout="handleMouseOut">
					<div v-for="(item,index) in secenlist" :key="index" @mouseover="handleSceneOver(index)" class="item"
						:class="currentSecenIndex == index ? 'img-item-mask' : 'img-item'">
						<div class="img-box">
							<img :src="item.src">
						</div>
						<div class="mask">
							<div class="btn">{{item.label}}</div>
						</div>
					</div>
				</div>
			</div>
		</el-row>

		<el-row :gutter="24" style="margin: 0;" class="parameter">
			<el-col :span="4">
				<div class="title">
					<span>参数</span>
					<el-image :src="require('@/assets/image/icon-arrow-down.png')" fit="fill"></el-image>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin: 0;" class="parameter-content">
			<div v-for="(item,index) in parameterlist" :key="index">
				<div class="item-box">
					<div class="value">{{item.value}}</div>
					<div class="line">—</div>
					<div class="label">{{item.label}}</div>
				</div>
			</div>
		</el-row>
		<bottom-bar></bottom-bar>
	</div>
</template>

<script>
	import customNav from '@/components/custom-nav.vue'
	import bottomBar from '@/components/bottomBar.vue';
	export default {
		name: 'typeSDetail',
		data() {
			return {
				valuechange:0,
				num: 0,
				swiperlist: [
					// 奇妙蛙
					[require('@/assets/image/swiper-item01-1.jpg'),
						require('@/assets/image/swiper-item01-2.jpg'),
						require('@/assets/image/swiper-item01-3.jpg'),
					],
					// 皮卡丘
					[require('@/assets/image/swiper-item02-1.jpg'),
						require('@/assets/image/swiper-item02-2.jpg'),
						require('@/assets/image/swiper-item02-3.jpg'),
					],
					// 萌小咪
					[require('@/assets/image/swiper-item03-1.jpg'),
						require('@/assets/image/swiper-item03-2.jpg'),
						require('@/assets/image/swiper-item03-3.jpg'),
					],
					// 四季鹿
					[require('@/assets/image/swiper-item04-1.jpg'),
						require('@/assets/image/swiper-item04-2.jpg'),
						require('@/assets/image/swiper-item04-3.jpg'),
					],
				],
				indicatorlist: [{
						src: require('@/assets/image/detail-indicator01.png'),
						value: 0
					},
					{
						src: require('@/assets/image/detail-indicator02.png'),
						value: 1
					},
					{
						src: require('@/assets/image/detail-indicator03.png'),
						value: 2
					},
					{
						src: require('@/assets/image/detail-indicator04.png'),
						value: 3
					}
				],
				currentIndicatorIndex: 0,
				borderColor: ['#4AB17D', '#D7B037', '#DB6A60', '#EE7C33'],
				introducelist: [{
						src: require('@/assets/image/introduce-item01.png'),
						label: '时间温控显现'
					},
					{
						src: require('@/assets/image/introduce-item02.png'),
						label: '可调节补光灯'
					},
					{
						src: require('@/assets/image/introduce-item03.png'),
						label: '倒卡扣圆角'
					},
					{
						src: require('@/assets/image/introduce-item04.png'),
						label: '静音温控风扇'
					}
				],
				currentIntroIndex: 0,
				secenlist: [{
						src: require('@/assets/image/secen-item01.png'),
						label: '办公室'
					},
					{
						src: require('@/assets/image/secen-item02.png'),
						label: '花店'
					},
					{
						src: require('@/assets/image/secen-item03.png'),
						label: '教室'
					},
					{
						src: require('@/assets/image/secen-item04.png'),
						label: '酒店'
					}
				],
				isMaskShow: false,
				currentSecenIndex: 0,
				parameterlist: [{
					label: '型号',
					value: 'FCJ-L'
				}, {
					label: '外形尺寸',
					value: '320*220*220mm'
				}, {
					label: '净重',
					value: '2kg'
				}, {
					label: '控制方式',
					value: 'APP'
				}, {
					label: '电压频率',
					value: '220V/50Hz'
				}, {
					label: '功率',
					value: '10W'
				}, {
					label: '种植容积尺寸',
					value: '276*101*45mm'
				}, {
					label: '材料',
					value: 'ABS'
				}],
				height: null
			}
		},
		created() {
			console.log(this.$route);
			this.currentIndicatorIndex = this.$route.query.index;
			this.height = document.documentElement.clientHeight
		},
		components: {
			customNav,
			bottomBar
		},
		mounted(){
			window.addEventListener('scroll',this.handleScroll,true)

		},
		watch:{
			
		},
		computed: {
			getColor() {
				return this.borderColor[this.currentIndicatorIndex]
			}
		},
		methods: {
			handleScroll(){
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				if(scrollTop>0){
					this.valuechange=1
				}else{
					this.valuechange=0
				}
			},
			handlePrev() {
				if (this.num == 0) {
					this.num = 3
				}
				this.num--;
			},
			handleNext() {
				if (this.num == 2) {
					this.num = -1
				}
				this.num++
			},
			handleIntruduceClick(val) {
				this.currentIntroIndex = val
			},
			handleIndicatorClick(val) {
				this.currentIndicatorIndex = val
			},
			handleSceneOver(index) {
				this.currentSecenIndex = index;
			},
			handleMouseOut() {
				this.currentSecenIndex = null
			},
			handleOrderClick() {
				window.location.href = "https://detail.tmall.com/item.htm?id=654011800008"
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.app-container {
		width: 100%;
		height: 100%;
		
		.active{
			.nav-container[data-v-22b7c952]{
				background-color: black;
			}
		}
		
		.swiper-focus {
			width: 100%;
			height: 100%;
			background: #F8F8F8;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.swiper-img {
				width: 100%;
				height: 100%;
				position: relative;

				.item-img {
					width: 100%;
					height: 100%;
				}
			}

			.swiper-indicator {
				height: 80px;
				width: 100%;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0px;
				background-color: #F8F8F8;
				// transform: translateY(-50%);
				display: flex;
				justify-content: center;

				.img {
					margin-right: 30px;
					position: relative;
					top: -100%;
					transition: all ease 0.3s;

					&:hover {
						transform: translateY(-30px);
					}
				}

				.currentimg::after {
					content: " ";
					position: absolute;
					top: 0%;
					bottom: -75%;
					right: 0;
					left: 0;
					z-index: 99;
					background-color: rgba(0, 0, 0, 0.5);
				}

			}

			.indicator-arrow {
				display: flex;
				justify-content: space-between;
				position: absolute;
				right: 0;
				left: 0;
				top: 45%;
				padding: 0 20px;

				img:first-child {
					transform: rotate(90deg);
				}

				img:last-child {
					transform: rotate(-90deg);
				}
			}
		}

		.box-content {
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.sub-title {
				font-size: 24px;
				font-weight: bold;
				color: #111111;
				padding-bottom: 20px;
				border-bottom: 2px solid #EEEEEE;
				position: relative;

				&::after {
					content: " ";
					width: 30px;
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: #4AB17D;
				}
			}
		}

		.introduce {

			.content {
				width: 100%;
				margin-top: 50px;
				display: flex;
				justify-content: center;
				align-items: stretch;

				.item {
					width: 20%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					border: 1px solid rgba(1, 1, 1, 0);
					padding: 60px 0;

					.label {
						margin-top: 58px;
						width: 200px;
						min-width: 100px;
						height: 55px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #EEEEEE;
						border-radius: 10px;
						font-size: 16px;
						color: #111111;
					}

					&:hover {
						border-color: #4AB17D;

						.label {
							background: #4AB17D;
							color: #FFFEFE;
						}
					}
				}

			}
		}

		@media screen and (max-width:1500px) {
			.introduce {

				.content {
					display: flex;

					.item {
						padding: 67px 30px 60px 30px;

						.label {
							width: 50%;
							font-size: 14px;
						}

						img {
							flex: auto;
							width: 50%;
						}
					}

				}
			}
		}

		.scene {
			margin-top: 100px;

			.sub-title {
				margin-bottom: 50px;
			}

			.img-box {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 50%;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.img-item-mask {
					position: relative;

					.mask {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						background-color: rgba(0, 0, 0, .5);
						display: flex;
						justify-content: center;
						align-items: center;

						.btn {
							background-color: rgba(74, 177, 125, .8);
							border-radius: 30px;
							font-size: 20px;
							color: #FFFFFF;
							padding: 20px 65px;
						}

						@media screen and (max-width:1500px) {
							.btn {
								font-size: 16px;
							}
						}
					}
				}

				.img-item {
					position: relative;

					.mask {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						z-index: -999;
						background-color: rgba(0, 0, 0, .5);
						display: flex;
						justify-content: center;
						align-items: center;

						.btn {
							background-color: rgba(74, 177, 125, .8);
							border-radius: 30px;
							font-size: 20px;
							color: #FFFFFF;
							padding: 20px 65px;
						}
					}
				}
			}
		}

		.parameter {
			padding: 43px 260px;

			.title {
				width: 100px;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					font-size: 24px;
					color: #111111;
					margin-right: 30px;
				}

				.el-image {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		@media screen and (max-width:1900px) {
			.parameter {
				padding: 43px 50px;

				.title {
					span {
						font-size: 18px;
					}
				}
			}
		}

		.parameter-content {
			display: flex;
			justify-content: center;
			padding-bottom: 50px;

			.item-box {
				margin: 0 50px;
				display: flex;
				flex-direction: column;

				div {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.value {
					font-size: 16px;
					color: #666666;
				}

				.label {
					font-size: 16px;
					color: #666666;
				}

				.line {
					margin: 20px 0;
					color: #BBBBBB;
				}

			}

		}

		@media screen and (max-width:1500px) {
			.parameter-content {

				.item-box {
					margin: 0 20px;

					.value {
						font-size: 12px;
						color: #666666;
					}

					.label {
						font-size: 12px;
						color: #666666;
					}

					.line {
						margin: 20px 0;
						color: #BBBBBB;
					}

				}
			}
		}
	}

	.order-btn1-1 {
		position: absolute;
		right: 18%;
		top: 45%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn1-1 {
			right: 17%;
		}
	}

	.order-btn1-2 {
		position: absolute;
		right: 14%;
		top: 45%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn1-2 {
			right: 12%;
		}
	}

	.order-btn1-3 {
		position: absolute;
		right: 14%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn1-3 {
			right: 12%;
		}
	}

	.order-btn2-1 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn2-1 {
			right: 14%;
		}
	}

	.order-btn2-2 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn2-2 {
			right: 15%;
		}
	}

	.order-btn2-3 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn2-3 {
			right: 14%;
		}
	}

	.order-btn3-1 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn3-1 {
			right: 14%;
		}
	}

	.order-btn3-2 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn3-2 {
			right: 15%;
		}
	}

	.order-btn3-3 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn3-3 {
			right: 14%;
		}
	}

	.order-btn4-1 {
		position: absolute;
		right: 15%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn4-1 {
			right: 14%;
		}
	}

	.order-btn4-2 {
		position: absolute;
		right: 15%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn4-2 {
			right: 13%;
		}
	}

	.order-btn4-3 {
		position: absolute;
		right: 16%;
		top: 40%;
		background: #ffffff;
		border-radius: 23px;
		padding: 16px 60px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1900px) {
		.order-btn4-3 {
			right: 14%;
		}
	}
</style>
